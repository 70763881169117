import React from 'react'
import '../styles/global.css'

export default function SlideShow() {
    return (
        <div className='process-screens-slider'>
            <div className='process-screen-slides'>
                <div className='process-screen-individual'>
                  <img src='landing-page.png' className='process-images' alt='landing page'/>
                  <p className='process-screens-captions'>Select product</p>
                </div>
                <div className='process-screen-individual'>
                  <img src='pdp.png' className='process-images' alt='product display page'/>
                  <p className='process-screens-captions'>Proceed for payment</p>
                </div>
                <div className='process-screen-individual'>
                  <img src='multiple-payment-options-together.png' className='process-images' alt='multiple payment options'/>
                  <p className='process-screens-captions'>Multiple payment options supported</p>
                </div>
                <div className='process-screen-individual'>
                  <img src='thank-you.png' className='process-images' alt='thank you'/>
                  <p className='process-screens-captions'>Easy product dispense process</p>
                </div>
            </div>
        </div>
    )
}
