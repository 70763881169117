import React from 'react'

export default function CodePenBeehive() {
    return (
        // https://codepen.io/gpyne/pen/iElhp reference
        <div>
            <div className='honeycomb'>
                <div className='ibws-fix first-beehive-row'>
                    <div className='hexagon light-green small-hexagon'>
                        <div className='hexagon-content'>
                        </div>
                    </div>
                    <div className='hexagon hidden'>
                        <div className='hexagon-content'>
                        </div>
                    </div>
                    <div className='hexagon light-green'>
                        <div className='hexagon-content'>
                        </div>
                    </div>
                    <div className='hexagon hidden'>
                        <div className='hexagon-content'>
                        </div>
                    </div>
                    <div className='hexagon light-green small-hexagon'>
                        <div className='hexagon-content'>
                        </div>
                    </div>
                </div>
                <div className='ibws-fix'>
                    <div className='hexagon dark-green'>
                        <div className='hexagon-content'>
                            <p className='beehive-heading'>Convenience</p>
                            <p className='beehive-text'>24x7 availability of items for snacking</p>
                        </div>
                    </div>
                    <div className='hexagon dark-green'>
                        <div className='hexagon-content'>
                            <p className='beehive-heading'>Discovery</p>
                            <p className='beehive-text'>All vending machines available on rent</p>
                        </div>
                    </div>
                    <div className='hexagon dark-green'>
                        <div className='hexagon-content'>
                            <p className='beehive-heading'>Wide Range of Food Products</p>
                            <p className='beehive-text'>Avail food and beverages in every category</p>
                        </div>
                    </div>
                    <div className='hexagon dark-green'>
                        <div className='hexagon-content'>
                            <p className='beehive-heading'>Professional Service</p>
                            <p className='beehive-text'>Timely refilling and all products sold at MRP</p>
                        </div>
                    </div>
                    <div className='hexagon dark-green'>
                        <div className='hexagon-content'>
                            <p className='beehive-heading'>Automatic Refund</p>
                            <p className='beehive-text'>Get instant refund in case of failure</p>
                        </div>
                    </div>
                </div>
                <div className='ibws-fix'>
                    <div className='hexagon light-green small-hexagon'>
                        <div className='hexagon-content'>
                        </div>
                    </div>
                    <div className='hexagon light-green'>
                        <div className='hexagon-content'>
                        </div>
                    </div>
                    <div className='hexagon hidden'>
                        <div className='hexagon-content'>
                        </div>
                    </div>
                    <div className='hexagon light-green small-hexagon'>
                        <div className='hexagon-content'>
                        </div>
                    </div>
                    <div className='hexagon hidden'>
                        <div className='hexagon-content'>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
