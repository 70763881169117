import React from "react"
import Layout from "../components/Layout"
import * as styles from '../styles/home.module.css'
import ContentCards from "../components/ContentCards"
import CodePenBeehive from "../components/CodePenBeehive"
import SlideShow from "../components/SlideShow"

export default function Home() {

  return (
      <Layout>
        {/* hero */}
        <ContentCards>
          <div className={styles.homeContainer}>
            <div className={styles.homeContent}>
              <p className={styles.heroContentLine1}>Smart Vending Machine</p>
              <p className={styles.heroContentLine2}>Made in India</p>
              <p className={styles.heroContentLine3}><span className='red-text'>Satisfy</span> your cravings anytime, anywhere</p>
              <p className={styles.heroContentLine4}>Get wide range of fresh food, snacks </p>
              <p className={styles.heroContentLine5}>Select, Scan, Grab and Go...</p>
            </div>
            <div className='home-frame'>
              <img className={styles.homeIllustration} src='hero-illustration.png' alt='people with vending machine'/>
            </div>
          </div>
        </ContentCards>

        {/* video */}

        <ContentCards>
        <div className={styles.homeContainer}>
            <div className={styles.videoContent}>
              <p className={styles.videoContentLine1}>Start using vending machines in your business</p>
              <p className={styles.videoContentLine2}>24x7 availability of items for snacking</p>
              <p className={styles.videoContentLine3}>Instantly get the products that you want without stepping out of your premises</p>
            </div>
            <div className={styles.videoFrame}>
            <iframe className={styles.embeddedVideo} src="https://www.youtube.com/embed/3eHk7Z_gz5Q" title="YouTube video player"></iframe>
            </div>
          </div>
        </ContentCards>

        {/* process */}

        <div className='process-card'>
          <img src='process-top-left.png' className='process-top-left-image' alt='girl process'/>
          <div className='process-text'>
            <p className='process-text-line-1'>No complicated dispense process</p>
            <p className='process-text-line-2'>Just <span className='red-text'>Scan, Grab and Go...</span></p>
          </div>
          <img src='process-bottom-right.png' className='process-bottom-right-image' alt='celebration process'/>
        </div>

        {/* process screens */}

        <div className={styles.processScreens}>
          <div className='process-screen-individual'>
            <img src='landing-page.png' className='process-images' alt='landing page'/>
            <p className='green-text'>Select product</p>
          </div>
          <div className='process-screen-individual'>
            <img src='pdp.png' className='process-images' alt='product display page'/>
            <p className='green-text'>Proceed for payment</p>
          </div>
          <div className='process-screen-individual'>
            <img src='multiple-payment-options-together.png' className='process-images' alt='multiple payment options'/>
            <p className='green-text'>Multiple payment options supported</p>
          </div>
          <div className='process-screen-individual'>
            <img src='thank-you.png' className='process-images' alt='thank you'/>
            <p className='green-text'>Easy product dispense process</p>
          </div>
        </div>

        {/* process-screens-mobile */}

        <div className='process-screens-mobile'>
          <SlideShow/>
        </div>

        {/* why vending with us */}
        <div className='why-vending-with-us'>
          <div className='why-vending-bg'>
            <p className='why-vending-text'>Why Vending with Us?</p>
          </div>
        </div>

        {/* beehive section */}
        <div className={styles.beehive}>
          <div className='beehive-container'>
            < CodePenBeehive/>
          </div>
        </div>

        <div className={ styles.beehiveMobile }>
          <div className={ styles.beehiveMobileText }>
            <p>Convenience</p>
            <p>24x7 availability of items for snacking</p>
          </div>
          <div className={ styles.beehiveMobileEmpty }></div>
          <div className={ styles.beehiveMobileEmpty }></div>
          <div className={ styles.beehiveMobileText }>
            <p>Discovery</p>
            <p>All vending machines available on rent</p>
          </div>
          <div className={ styles.beehiveMobileText }>
            <p>Wide Range of Food Products</p>
            <p>Avail food and beverages in every category</p>
          </div>
          <div className={ styles.beehiveMobileEmpty }></div>
          <div className={ styles.beehiveMobileEmpty }></div>
          <div className={ styles.beehiveMobileText }>
            <p>Professional Service</p>
            <p>Timely refilling and all products sold at MRP</p>
          </div>
          <div className={ styles.beehiveMobileText }>
            <p>Automatic Refund</p>
            <p>Get instant refund in case of failure</p>
          </div>
          <div className={ styles.beehiveMobileEmpty }></div>
        </div>

        <div className={ styles.honeycombBottomStrip }>
          <div className={ styles.honeycombBottomStripBg }>
            <div className={styles.honeycombStripImageAndCaption}>
              <img src='vending-machine-left-side-view.png' alt='vending machine' />
              <p className={ styles.honeycombStripCaption }>Vending Machine</p>
            </div>
            <div className={styles.honeycombStripImageAndCaptionPlusOrEquals}>
              {/* <img src='plus.png' /> */}
              <p className={styles.plusText}>+</p>
            </div>
            <div className={styles.honeycombStripImageAndCaption}>
              <img src='15-6-touch-screen.png' alt='touch screen' />
              <p className={ styles.honeycombStripCaption }>Technology/Software</p>
            </div>
            <div className={styles.honeycombStripImageAndCaptionPlusOrEquals}>
              {/* <img src='plus.png' /> */}
              <p className={styles.plusText}>+</p>
            </div>
            <div className={styles.honeycombStripImageAndCaption}>
              <img src='scooter-guy.png' alt='supply chain' />
              <p className={ styles.honeycombStripCaption }>Supply-chain</p>
            </div>
            <div className={styles.honeycombStripImageAndCaptionPlusOrEquals}>
              {/* <img src='equals.png' /> */}
              <p className={styles.equalsText}>=</p>
            </div>
            <div className={styles.honeycombStripImageAndCaption}>
              <img src='all-under-one-roof.png' alt='all under one roof' />
              <p className={ styles.honeycombStripCaption }>All under one roof</p>
            </div>
          </div>
        </div>

        {/* contact us section */}

        <div className={styles.contactUsSection} id='contactUs'>
          <div className='contact-us-form'>
            <div className='form-content'>
              <h1>Ready to get started</h1>
              <h3>We are happy to help you with all your vending needs. Simply fill the form and our experts will call you.</h3>
              <div>
                <form action='https://getform.io/f/e9c87226-820c-42e2-9931-271d7f5468fb' method='POST'>
                  <input className='contact-us-form-input' type='text' id='name' name='name' placeholder='Name'></input> <br />
                  <input className='contact-us-form-input' type='email' id='email' name='email' placeholder='Email'></input> <br />
                  <input className='contact-us-form-input' type='number' id='phone' name='phone' placeholder='Phone Number'></input> <br />
                  <button type='submit' className='reqeust-a-call-button'>Request a Call</button>
                </form>
              </div>
            </div>
          </div>
          <img src='contact-us-illustration.png' className={styles.contactUsIllustration} alt='contact us illustration' />
          <img src='contact-us-illustration.png' className={styles.contactUsIllustration} alt='contact us illustration' />
          <div className={styles.contactUsReachUs}>
            <div className='contact-us-reach-us-content'>
              <h1>Reach Us</h1>
              <h3>Feel free to reach us anytime for any queries</h3>
              <div className='contact-us-official-details-section'>
                  <table>
                    <tbody>
                        <tr>
                            <td>
                                <img className='footer-icons' src='location.svg' alt='location svg' />
                            </td>
                            <td>Shop no 15, Golden City Center, Beside Prozone Mall, Aurangabad - 431003, Maharashtra, India</td>
                        </tr>
                        <tr>
                            <td><img className='footer-icons' src='phone.svg' alt='phone svg' /></td>
                            <td>Phone No. 9607022226</td>
                        </tr>
                        <tr>
                            <td>
                                <img className='footer-icons' src='email.svg' alt='email svg' />
                            </td>
                            <td>contact@mercados.in</td>
                        </tr>
                      </tbody>
                  </table>
              </div>
            </div>
          </div>
        </div>

      </Layout>
    )
}
